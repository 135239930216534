import React, { useState, useReducer, useEffect } from 'react';
import { Modal, Button, Spinner, Alert } from "react-bootstrap";
import axios from 'axios';
import { useSelector } from "react-redux";
import Config from '../../config';

const UpdateHeader = ({ isShow, closeModal, setFlag, flag, currentPost }) => {
    const user = useSelector((state) => state.sessionStore);
    const [buttonStatus, setButtonStatus] = useState(false)
    const [showAlert, setAlertShow] = useState(false);
    const [message, setMessage] = useState()


    const handleClose = () => {
        closeModal(false);
    };

    const [formInput, setFormInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            buttonText:"",
            buttonColor:true,
            buttonLink:"",
            buttonVisibility:"true"
        }
    );
    const handleInput = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setFormInput({ [name]: newValue });
    };
    useEffect(() => {
        setFormInput(currentPost)
    }, [currentPost])



    const createBook = (e) => {
        e.preventDefault();
        setButtonStatus(true);
        let url = Config.BASE_URL + "/api/admin/update_page/header";


        axios.post(url, {
            buttonText:formInput.buttonText,
            buttonColor:formInput.buttonColor,
            buttonLink:formInput.buttonLink,
            buttonVisibility:formInput.buttonVisibility
        }, {
            headers: {
                "authorization": `Bearer ${user.token}`
            }
        }).then((res) => {
            if (res.data.success === true) {
                setFlag(!flag)
                setButtonStatus(false);
                handleClose()
            } else {
                throw new Error(res.data.message)
            }

        }).catch((e) => {
            setAlertShow(true)
            setMessage(e.message)
            setButtonStatus(false);
        })
    }

    const handleInput3= evt => {
        const name = evt.target.name;
        setFormInput({ [name]: !formInput[name] });
    };
    return (
        <>
            <Modal show={isShow} onHide={handleClose} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Update button</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="danger" show={showAlert} onClose={() => setAlertShow(false)} dismissible><p>{message}</p></Alert>
                    <form onSubmit={createBook}>
                        <div className="form-group row">
                            <div className="col-md-12">
                                <label htmlFor="buttonVisibility">Button Visibility: </label>
                                <select
                                    id='buttonVisibility'
                                    type="text"
                                    onChange={handleInput}
                                    value={formInput.buttonVisibility}
                                    className="form-control"
                                    name='buttonVisibility'
                                    required
                                >
                                    <option value={"hide"}>Hide from all user</option>
                                    <option value={"all"}>Show to from all user</option>
                                    <option value={"non_sub_user"}>Show to non-sub user only</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12">
                                <label htmlFor="buttonText">Button text </label>
                                <input
                                    id='buttonText'
                                    type="text"
                                    onChange={handleInput}
                                    value={formInput.buttonText}
                                    className="form-control"
                                    placeholder="Enter buttonText"
                                    name="buttonText"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12">
                                <label htmlFor="buttonLink">Button link </label>
                                <input
                                    id='buttonLink'
                                    type="text"
                                    onChange={handleInput}
                                    value={formInput.buttonLink}
                                    className="form-control"
                                    placeholder="Enter buttonLink"
                                    name="buttonLink"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-md-12">
                                <label htmlFor="title">Button Color</label>
                                <input type="color" className="custom_check" value={formInput.buttonColor} name="lc_s" onChange={(e) => setFormInput({ ["buttonColor"]: e.target.value })} />
                            </div>
                        </div>
                        <br/>
 

                        <div className="row justify-content-center">
                            {buttonStatus ? (
                                <Button
                                    variant="primary"
                                    disabled
                                    className="btn btn-block btn-primary mb-4"
                                >
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Updating...
                                </Button>
                            ) : (
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="btn btn-block btn-primary mb-4"
                                >
                                    Update
                                </Button>
                            )}
                        </div>

                    </form>
                </Modal.Body>

            </Modal>
        </>
    );
};

export default UpdateHeader;