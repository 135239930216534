import React, { useEffect, useState, useRef } from "react";
import { useWindowSize } from '../../utils/custom-hooks';
import { Alert, Button, Badge, Table, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { removeSession } from '../../store/action/sessionAction';
import { Helmet } from 'react-helmet'
import Select, { components } from 'react-select';
import * as $ from "jquery";
import Config from "../../config";
import AddNewUpdate from './AddNewUpdate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrashAlt, faQuestionCircle, faKeyboard,faCheck ,faStar} from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
import moment from 'moment';
import UpdateHeader from './updateHeader'
import TagModal from './tagModal'
import TopTag from './topTag'
import ReactLoading from 'react-loading';
import ReactDOM from 'react-dom';
import { useParams,useLocation  } from "react-router-dom";
import { isArray } from "lodash";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import capitalizeWords from '../../utils/capitalizeWords'

$.DataTable = require("datatables.net-bs");
require("datatables.net-responsive-bs");



let g_reqBody=null,use_g_reqBody=false
let g_currentFilter = [], g_crntMode = "all", g_randomize = false, g_randomizeArray = [], g_filterFormat = "",g_prev_filterFormat="", g_is_mobile = false, g_public_only = "all", g_show_date_col = false, g_show_tag_col = true;
let hasMoreGlobal = true, updateDataCount = 0, alreadyCalled = false, table_rendered = false, g_show_pre_title = false,g_search_text="",g_showRedLink=true,g_DateFilter=null,g_readStatus=""
function drawTable(data, goTo, click_data, setLoading, setHasMore, setPrivateCount, setPublicCount, set_updateData, setRandomizeArray, isEditMode, editEntry, deleteUpdateEntry,
    showTagModal, setIsShowSettingFunction, setTopTagModalFunction, settingButtonRef, tagModalButtonRef, resetTagButtonRef, resetRandomize,randomizeButton,doRandomize,setVideoCount,setArticleCount,
userData,current_mode,markAsRead,markAsFav,setReadCount) {
    let tableZero = "#update_page_table";
    $.fn.dataTable.ext.errMode = "throw";
    // @ts-ignore

    $.fn.DataTable.ext.pager.numbers_length = 5;

    let columns = [
        {
            "data": null,
            "searchable": false,
            "orderable": true,
            "render": function (data, type, full, meta) {
                return ''
            }
        }
    ]
    let columnDefs = [
        {
            targets: 0,
            createdCell: (td, cellData, rowData, row, col) =>
                ReactDOM.render(
                    <>
                        <a onClick={() => {
                            goTo(rowData);
                            // $(td).find('a').css('color', 'purple');
                        }} style={{ cursor: "pointer", color:rowData?.stock_post === true?'green':rowData?.jd_teaching === true?'purple': g_showRedLink && rowData.is_public?'red':'blue', width: "90%" ,}} className={`mobile_headline ${rowData?.is_read ? 'strikethrough':''}`}><span>
                                {rowData?.is_fav && <span style={{marginRight:'5px'}}><FontAwesomeIcon icon={faStar} color="#FFCB4C" /></span>}
                                {g_show_pre_title && rowData?.pre_title && `${capitalizeWords(rowData.pre_title)} : `}
                                {capitalizeWords(rowData.title)}
                                </span></a>
                        {isEditMode && <span className="ml-2">
                            <button className="btn btn-sm" onClick={() => editEntry(rowData, row)} style={{

                            }}><FontAwesomeIcon icon={faPen} color="blue" /></button>
                            <button className="btn btn-sm ml-1" onClick={() => deleteUpdateEntry(rowData)} style={{

                            }}><FontAwesomeIcon icon={faTrashAlt} color="red" /></button>
                        </span>}
                        {userData?.token && current_mode === 'edit' && <>
                            <button className="btn btn-sm ml-1" onClick={() => markAsRead(rowData)} style={{
                            }}><FontAwesomeIcon icon={faCheck} color={`${rowData?.is_read ? 'red' : 'blue'}`} /></button>

                            <button className="btn btn-sm ml-1" onClick={() => markAsFav(rowData)} style={{
                            }}><FontAwesomeIcon icon={faStar} color={`${rowData?.is_fav ? 'red' : '#FFCB4C'}`} /></button>
                        </>}
                    </>, td),
        }
    ]

    if (g_show_date_col === true) {
        columns = [columns[0]]
        columns.push({
            data: "date",
            render: function (data, type, row) {
                return `<span style="font-size:${g_is_mobile === true ? '12px !important' : '16px'}">${moment(data).format("DD/MM/YYYY")}</span>`;
            },
        })
    }
    if (g_show_tag_col) {
        columns.push({
            "data": null,
            "searchable": false,
            "orderable": true,
            "render": function (data, type, full, meta) {
                return ''
            }
        })

        columnDefs.push({
            targets: g_show_date_col === true ? 2 : 1,
            createdCell: (td, cellData, rowData, row, col) =>
                ReactDOM.render(
                    <>
                        <button className="btn btn-sm btn-light" onClick={() => showTagModal(rowData)} style={{}}><FontAwesomeIcon icon={faQuestionCircle} /> </button>
                    </>, td),
        })
    }

    var datatable = $(tableZero).DataTable({
        pageLength: 50,
        dom: "frtp",
        "order": g_show_date_col === true ? [[1, "desc"]] : [],
        ajax: function (data, callback, settings) {
            let sorting = settings.aaSorting
            let tag = ""
            setLoading(true)
            if (g_currentFilter.length > 0) {
                tag = g_currentFilter.map((obj) => obj.value)
                tag = tag.join(",")
            }
            var current_page = Math.ceil(settings._iDisplayStart / settings._iDisplayLength) + 1;

            const jwtToken = JSON.parse(localStorage.getItem('jwtToken'));
            let role = jwtToken?.role === "iamtheboss" ? true : false
    
            if(g_randomize === false && g_filterFormat === "" && g_prev_filterFormat.length==0){
                g_search_text=data.search.value
            }else{
                g_prev_filterFormat=g_filterFormat
                if(g_search_text!==data.search.value && data.search.value.length>0){
                    g_search_text=data.search.value
                }
                $('div.dataTables_filter input').val(g_search_text);
            }
            let reqBody={
                readStatus:g_readStatus,
                tag: tag,
                filter: g_crntMode,
                date_filter:g_DateFilter,
                public_only: g_public_only,
                format: g_filterFormat,
                page: current_page,
                limit: 50,
                randomize: g_randomize,
                randomizeArray: g_randomizeArray,
                search: g_search_text,
                sort_by: g_show_date_col === false ? [1, "desc"] : sorting[0],
                role
            }
            if(use_g_reqBody===true){
                reqBody=g_reqBody
                use_g_reqBody=false
            }else{
                g_reqBody=reqBody
            }
            axios.post(`${Config.BASE_URL}/api/admin/update_page_data`,reqBody,
                {
                    headers:{
                        authorization:"Bearer "+userData?.token
                    }
                }
            ).then((res) => {
                const newData = res.data.data;
                // setHasMore(newData.length > 0);
                setPrivateCount(res.data.count.sub)
                setPublicCount(res.data.count.public)
                setReadCount(res.data.count.readCount)
                setVideoCount(res.data.count.video)
                setArticleCount(res.data.count.article)

                set_updateData(newData);
                updateDataCount = newData.length
                
                if (g_randomize && g_randomizeArray.length === 0) {
                    g_randomizeArray = res.data.randomizeArray
                    setRandomizeArray(res.data.randomizeArray)
                    if (g_is_mobile) {
                        window.scrollTo(0, 125);
                    } else {
                        window.scrollTo(0, 165);
                    }
                }

                const tmpJson = {
                    recordsTotal: res.data.totalPages * 50,
                    recordsFiltered: res.data.totalPages * 50,
                    data: res.data.data,
                    currentPage: res.data.currentPage
                }
                setLoading(false)
                callback(tmpJson)
            }).catch((e) => {
                if (e.response != null && e.response.data != null && e.response.data.code == 401) {
                    // logoutUser()
                }
            });
        },
        displayStart: 0,
        serverSide: true,
        bFilter: false,
        "searching": true,
        "bLengthChange": false,
        "ordering": true,
        columns: columns,
        columnDefs: columnDefs,
        // "createdRow": function (row, data, dataIndex) {
        //     if (data.is_public === true) {
        //         $(row).addClass('public-link');
        //     }
        // },
        language: {
            searchPlaceholder: "Search with text..",
            "search": "_INPUT_"
        },
        drawCallback: function (settings, json) {
            const replaceDiv=()=>{
                var searchBox = $("#update_page_table_filter");
                const resetRandom = `<div class="col-auto cross-btn-div" style="padding: 0;width:40px;">
                <button class="btn" id="resetTagButton">
                <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 384 512" color="#1c56ac"><path fill="#fa0000" d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
                </button>
            </div>`
                var customDiv = $(`
                    <div class="row my-custom-div" style="padding-top:8px;" id="my-custom-div"> 
                        <div class="col-auto" style="padding: 0; width:40px;">
                            <button class="btn" id="settingButton" >
                            <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512" color="#1c56ac"><path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" /></svg>
                            </button>
                        </div>
                        <div class="col-auto" style="padding: 0;width:40px;">
                            <button class="btn" id="tagModalButton">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" /></svg>
                            </button>
                        </div>
                        <div class="col-auto" style="padding: 0;width:40px;">
                            <button class="btn" id="randomizeButton">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512" color="#1c56ac"><path d="M403.8 34.4c12-5 25.7-2.2 34.9 6.9l64 64c6 6 9.4 14.1 9.4 22.6s-3.4 16.6-9.4 22.6l-64 64c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6V160H352c-10.1 0-19.6 4.7-25.6 12.8L284 229.3 244 176l31.2-41.6C293.3 110.2 321.8 96 352 96h32V64c0-12.9 7.8-24.6 19.8-29.6zM164 282.7L204 336l-31.2 41.6C154.7 401.8 126.2 416 96 416H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H96c10.1 0 19.6-4.7 25.6-12.8L164 282.7zm274.6 188c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6V416H352c-30.2 0-58.7-14.2-76.8-38.4L121.6 172.8c-6-8.1-15.5-12.8-25.6-12.8H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H96c30.2 0 58.7 14.2 76.8 38.4L326.4 339.2c6 8.1 15.5 12.8 25.6 12.8h32V320c0-12.9 7.8-24.6 19.8-29.6s25.7-2.2 34.9 6.9l64 64c6 6 9.4 14.1 9.4 22.6s-3.4 16.6-9.4 22.6l-64 64z"/></svg>
                            </button>
                        </div>
                        ${g_randomize === true || g_currentFilter.length>0 || g_search_text.length>0 ? resetRandom : '<span></span>'}
                    </div>
                 
                `);
                searchBox.after(customDiv);

                settingButtonRef.current = document.getElementById("settingButton");
                tagModalButtonRef.current = document.getElementById("tagModalButton");
                randomizeButton.current = document.getElementById("randomizeButton");

                settingButtonRef.current.addEventListener("click", setIsShowSettingFunction);
                tagModalButtonRef.current.addEventListener("click", setTopTagModalFunction);
                randomizeButton.current.addEventListener("click", doRandomize);
                if (g_randomize || g_currentFilter.length>0 || g_search_text.length>0) {
                    resetTagButtonRef.current = document.getElementById("resetTagButton");
                    resetTagButtonRef.current.addEventListener("click", resetRandomize);
                }
            }
            if ($(".my-custom-div").length === 0) {
                replaceDiv()
            } else if ($(".cross-btn-div").length === 0) {
                if (g_search_text.length > 0) {
                    var customDiv = $(".my-custom-div")
                    customDiv.remove()
                    replaceDiv()
                }
            }
        }
    });

    $("#update_page_table").on("page.dt", function () {
        $("html, body").animate(
            {
                scrollTop: 0,
            },
            300
        );
    });



}



const handleHeaderClick = (id, setIsCollapsed) => {
    const node = document.querySelector(`#${id}`).parentElement.nextElementSibling;
    const classes = node.classList;
    const isCollapsed = classes.contains("collapsed_filter");
    if (isCollapsed) {
        node.classList.remove("collapsed_filter");
    } else {
        node.classList.add("collapsed_filter");
    }
    setIsCollapsed(!isCollapsed);
};

const CustomGroupHeading = (props) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    useEffect(() => {
        if (props.id) {
            handleHeaderClick(props.id, setIsCollapsed);
        }
    }, [props.id]);
    return (
        <div
            className="group-heading-wrapper"
            onClick={() => handleHeaderClick(props.id, setIsCollapsed)}
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
            <components.GroupHeading {...props}>
                <div className="custom-group-heading">{props.children} {isCollapsed ? '▼' : '▲'}</div>
                <hr style={{ padding: 0, margin: 0 }} />
            </components.GroupHeading>

        </div>
    );
};

const OptionComponent = props => {
    return (
        <components.Option {...props}>
            <div className="custom-option">{props.label}</div>
        </components.Option>
    );
};
const customStyles = {
    menuList: base => ({
        ...base,
        minHeight: "700px" // your desired height
    })
};

const UpdatesPage = () => {
    const params = useParams()
    const location = useLocation();

    const [link_clicked, setLinkClicked] = useState(JSON.parse(localStorage.getItem("link_clicked") || "{}"))
    const user = useSelector((state) => state.sessionStore);
    const current_mode = useSelector((state) => state.dataStore.gallery_mode);
    const dispatch = useDispatch()
    const [isEditMode, setIsEditMode] = useState(false)
    const [currentMode,setCrntMode]=useState("read")

    const windowSize = useWindowSize()
    const [showAlert, setAlertShow] = useState(false);
    const [message, setMessage] = useState()

    const [updateData, set_updateData] = useState([])
    const [addNewEntryModal, showAddNewEntryModal] = useState(false);
    const [sort_by, setSortBy] = useState("")
    const [filter_status, setfilterStatus] = useState(false);
    const [currentFilter, setCurrentFilter] = useState([])
    const [filter_list, setFilterList] = useState([])
    const [hiddenTag, setHiddenTag] = useState([])
    const [headerData, setHeaderData] = useState({})
    const [currentPost, setCurrentUpdate] = useState({})
    const [currentIndex, setIndex] = useState(9999);
    const [crntMode, setCurrentMode] = useState("all")
    const [publicCount, setPublicCount] = useState(0)
    const [privateCount, setPrivateCount] = useState(0)
    const [videoCount, setVideoCount] = useState(0)
    const [readCount,setReadCount]=useState(0)
    const [articleCount, setArticleCount] = useState(0)
    const [flag, setFlag] = useState(true)
    const [flag2, setFlag2] = useState(true)
    const [isEdit, setIsEdit] = useState(false)
    const [showUpdateHeaderModal, setShowUpdateHeaderModal] = useState(false)
    const [tagModal, setTagModal] = useState(false)
    const [showKeyboard, setShowKeyboard] = useState(false)
    const [isSearchable, setIsSearchAble] = useState(false)
    const [headlineOnly, setHeadlineOnly] = useState(true)
    const [filterFormat, setFilterFormat] = useState("")
    const [showTag, showTagFilter] = useState(true)
    const [loading, setLoading] = useState(false)
    const [getData, setGetData] = useState(false)
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [randomize, setRandomize] = useState(false)
    const [randomizeArray, setRandomizeArray] = useState([])
    const loader = useRef(null);
    const [loading2, setLoading2] = useState(false)
    const [confirmDeleteModalShow, setConfirmDeleteModalShow] = useState(false)
    const [currentUpdateEntry, setCurrentUpdateEntry] = useState({})
    const [isShowSetting, setIsShowSetting] = useState(false)
    const [currentRowId, setRowId] = useState(0)
    const [filter_option, setFilterOption] = useState([])
    const [publicOnly, setPublicOnly] = useState(location.pathname.includes("public") ?"public":"all")
    const [showDateRow, setShowDateRow] = useState(false)
    const [showTagRow, setShowTagRow] = useState(true)
    const [topTagModal, setTopTagModal] = useState(false)
    const [showRedLink,setShowRedLink]=useState(true)
    const [dateFilter,setDateFilter]=useState(null)
    const [showPreTitle,seShowPreTitle]=useState(false)
    const [is_show_fav,setis_show_fav]=useState(false)

    const [readStatus,setReadStatus]=useState("")
    const settingButtonRef = useRef(null);
    const tagModalButtonRef = useRef(null);
    const resetTagButtonRef = useRef(null);
    const randomizeButton = useRef(null);

    const makeSearchAble = () => {
        setIsSearchAble(true)
    }
    const DropdownIndicator = props => {
        return (
            <>
                <components.DropdownIndicator {...props}>
                    <span className="custom-dropdown-indicator" />
                    {showKeyboard === true && <button className="btn btn-sm" onTouchStartCapture={makeSearchAble}><FontAwesomeIcon icon={faKeyboard} /></button>}
                </components.DropdownIndicator>
            </>
        );
    };

    useEffect(()=>{
        if(location.pathname.includes("public")){
            g_public_only="public"
        }
    },[])

    useEffect(() => {
        if (current_mode === "read") {
            setIsEditMode(false)
            if (user.role === "iamtheboss") {
                setHeadlineOnly(true)
            }
        } else {
            if (user.role === "iamtheboss") {
                setIsEditMode(true)
                setHeadlineOnly(true)
            } else {
                setIsEditMode(false)
            }
        }
        setCrntMode(current_mode)
    }, [current_mode, user])


    const ma_filter_func = (value) => {
        if (isArray(value)) {
            g_currentFilter = value;
            setCurrentFilter(value)
        } else {
            if (value !== null) {
                g_currentFilter = [value];
                setCurrentFilter([value])
            } else {
                g_currentFilter = [];
                setCurrentFilter([])
            }
        }

    }
    useEffect(() => {
        if (params?.tag_link) {
            axios.get(Config.BASE_URL + "/api/admin/update_page/get_link_tag/" + params?.tag_link, {
                headers: {
                    "authorization": `Bearer ${user.token}`
                }
            }).then((res) => {
                if (res.data.data) {
                    g_currentFilter = res.data.data
                    setCurrentFilter(res.data.data)
                    table_rendered = true
                }
            }).catch((e) => {
                table_rendered = true
            })
        }
    }, [params?.tag_link])

    useEffect(() => {
        if (params?.date_string) {
            g_DateFilter=params?.date_string
            const formattedDate = `${g_DateFilter.slice(0, 4)}-${g_DateFilter.slice(4, 6)}-${g_DateFilter.slice(6, 8)}`;
            const date = new Date(formattedDate);
            setDateFilter(date)
            table_rendered = true
        }
    }, [params?.date_string])

    useEffect(() => {
        // g_show_pre_title=showPreTitle
        const changes = [];
    
        if (prevValues.current.currentFilter !== currentFilter) changes.push('currentFilter');
        if (prevValues.current.flag !== flag) changes.push('flag');
        if (prevValues.current.sort_by !== sort_by) changes.push('sort_by');
        if (prevValues.current.crntMode !== crntMode) changes.push('crntMode');
        if (prevValues.current.filterFormat !== filterFormat) changes.push('filterFormat');
        if (prevValues.current.isEditMode !== isEditMode) changes.push('isEditMode');
        if (prevValues.current.publicOnly !== publicOnly) changes.push('publicOnly');
        if (prevValues.current.showDateRow !== showDateRow) changes.push('showDateRow');
        if (prevValues.current.showTagRow !== showTagRow) changes.push('showTagRow');
        if (prevValues.current.showRedLink !== showRedLink) changes.push('showRedLink');
        if (prevValues.current.showPreTitle !== showPreTitle) changes.push('showPreTitle');
        if (prevValues.current.currentMode !== currentMode) changes.push('currentMode');
        

        updateDataCount = 0;
        set_updateData([])
        setRandomizeArray([])
        g_randomizeArray = []
        setPage(1)
        setHasMore(true)
        setGetData(!getData)

        if(changes.includes('isEditMode')){
            use_g_reqBody=true
        }

        prevValues.current = { currentFilter, flag, sort_by, crntMode, filterFormat, isEditMode, publicOnly, showDateRow, showTagRow, showRedLink, showPreTitle, currentMode };

    }, [currentFilter, flag, sort_by, crntMode, filterFormat, isEditMode, publicOnly, showDateRow, showTagRow,showRedLink,showPreTitle,currentMode,readStatus])

    // Using useRef to store previous values
    const prevValues = useRef({
        currentFilter, flag, sort_by, crntMode, filterFormat, isEditMode, publicOnly, showDateRow, showTagRow, showRedLink, showPreTitle, currentMode
    });

    useEffect(() => {
        if (randomize) {
            setPage(1)
            setRandomizeArray([])
            g_randomizeArray = []
            setHasMore(true)
            set_updateData([])
            setGetData(!getData)
        }
    }, [flag2])

    const setTopTagModalFunction = () => {
        setTopTagModal(true); 
        window.location.hash = "#modal";
    }
    const setIsShowSettingFunction = () => {
        setIsShowSetting(true)
    }

    const markAsRead = (data) => {
        axios.post(Config.BASE_URL + `/api/user/posts/${data._id}/read`, 
        {
            isRead:data.is_read?false:true
        }, 
        {
            headers: {
                "authorization": `Bearer ${user.token}` 
            }
        }).then((res) => {
            if (res.data.success) {
                use_g_reqBody=true
                setGetData(!getData)
            } else {
                console.log('Failed to mark post as read');
            }
        }).catch((e) => {
            console.error('Error marking post as read', e);
        });
    };
    const markAsFav = (data) => {
        axios.post(Config.BASE_URL + `/api/user/posts/${data._id}/fav`, 
        {
            isRead:data.is_fav?false:true
        }, 
        {
            headers: {
                "authorization": `Bearer ${user.token}` 
            }
        }).then((res) => {
            if (res.data.success) {
                use_g_reqBody=true
                setGetData(!getData)
                setis_show_fav(res.data.is_show_fav)
            } else {
                console.log('Failed to mark post as read');
            }
        }).catch((e) => {
            console.error('Error marking post as read', e);
        });
    };
    

    useEffect(() => {
        let isMounted = true;
        const fetchData = () => {
            const click_data = JSON.parse(localStorage.getItem("link_clicked") || "{}")
            if ($.fn.dataTable.isDataTable("#update_page_table")) {
                let table = $("#update_page_table").DataTable();
                table.destroy();
                drawTable(updateData, goTo, click_data, setLoading, setHasMore, setPrivateCount, setPublicCount, set_updateData, setRandomizeArray, isEditMode, editEntry, deleteUpdateEntry,
                    showTagModal, setIsShowSettingFunction, setTopTagModalFunction, settingButtonRef, tagModalButtonRef, resetTagButtonRef, resetRandomize,randomizeButton,doRandomize,setVideoCount,setArticleCount,
                user,currentMode,markAsRead,markAsFav,setReadCount);
            } else {
                drawTable(updateData, goTo, click_data, setLoading, setHasMore, setPrivateCount, setPublicCount, set_updateData, setRandomizeArray, isEditMode, editEntry,
                    deleteUpdateEntry, showTagModal, setIsShowSettingFunction, setTopTagModalFunction, settingButtonRef, tagModalButtonRef, resetTagButtonRef, resetRandomize,randomizeButton,doRandomize,setVideoCount,setArticleCount,
                user,currentMode,markAsRead,markAsFav,setReadCount);
            }
        }
        if (params?.tag_link || params?.date_string) {
            if (table_rendered === true) {
                fetchData();
            }
        } else {
            fetchData();
        }
        return () => {
            isMounted = false;
            hasMoreGlobal = true;
            updateDataCount = 0;
        };
    }, [getData]);

    useEffect(() => {

        const jwtToken = JSON.parse(localStorage.getItem('jwtToken'));

        axios
            .get(Config.BASE_URL + "/api/admin/update_page/tag",
                {
                    headers:{
                        authorization:"Bearer "+jwtToken?.token
                    }
                }
            ).then((res) => {
                setFilterList(res.data.data)
                setHiddenTag(res.data.hidden_tag)
                setHeaderData(res.data.headerData)
                setFilterOption(res.data.filter_option)
                setis_show_fav(res.data.is_show_fav)
            }).catch((e) => {
                if (e.response != null && e.response.data != null && e.response.data.code == 401) {
                    dispatch(removeSession())
                }
            });
        return () => {
            g_currentFilter = [];
            g_crntMode = "all"; g_randomize = false; g_randomizeArray = []; g_public_only = g_public_only == "pakya_only" ? "pakya_only" : "all";
            alreadyCalled = false;
            g_showRedLink = true;
            g_filterFormat = "";
            g_prev_filterFormat="";
            g_is_mobile = false;
            table_rendered = false;
            g_show_date_col = false;
            g_show_tag_col = true;
            g_show_pre_title = false;
            g_DateFilter=null;
        }
    }, [flag])

    const editEntry = (data, row) => {
        setRowId(row)
        setCurrentUpdate(data)
        setIsEdit(true)
        showAddNewEntryModal(true)
    }
    const deleteEntry = () => {
        axios.delete(Config.BASE_URL + "/api/admin/update_page/" + currentUpdateEntry._id, {
            headers: {
                "authorization": `Bearer ${user.token}`
            }
        }).then((res) => {
            if (res.data.success === true) {
                setFlag(!flag)
                setConfirmDeleteModalShow(false)
            } else {
                setConfirmDeleteModalShow(false)
                setAlertShow(true)
                setMessage(res.data.message)
            }
        }).catch((e) => {
            setConfirmDeleteModalShow(false)
            setAlertShow(true)
            setMessage(e.message)
        })
    }
    const moveToPage = () => {
        window.open(headerData?.buttonLink, "_blank");
    }
    const showUpdateHeader = () => {
        setShowUpdateHeaderModal(true)
    }
    const showTagModal = (data) => {
        window.location.hash = "#modal"
        setCurrentUpdate(data)
        setTagModal(true)
    }
    useEffect(() => {
        if (windowSize?.width < 900) {
            g_is_mobile = true;
            setShowKeyboard(true)
        } else {
            if (windowSize?.width) {
                g_is_mobile = false;
                setShowKeyboard(false)
                setIsSearchAble(true)
            }
        }
    }, [windowSize])
    const handleMenuClose = () => {
        if (windowSize?.width < 900) {
            setIsSearchAble(false)
        }
    }
    const handleInput3 = evt => {
        setHeadlineOnly(!headlineOnly)
    };
    useEffect(() => {
        if (crntMode === "all_case_studies" || crntMode === "jd_teaching") {
            showTagFilter(true)
        } else {
            showTagFilter(false)
        }
    }, [crntMode])

    const doRandomize = () => {
        g_randomize = true
        setRandomize(true)
        setFlag2(!flag2)
    }
    const resetRandomize = () => {
        g_prev_filterFormat=""
        g_randomize = false
        setRandomize(false)
        set_updateData([])
        setRandomizeArray([])
        setGetData(!getData)
        ma_filter_func(null)
        // g_DateFilter=null
    }
    const goTo = (data) => {
        try {
            // setLinkClicked({ ...link_clicked, [data._id]: true })
            // localStorage.setItem('link_clicked', JSON.stringify({ ...link_clicked, [data._id]: true }));
            if(data?.open_same_tab){
                window.location.href=data.link
            }else{
                window.open(data.link, '_blank', 'noopener,noreferrer')
            }
        } catch (e) { }
    }
    const resetLinkColor = () => {
        set_updateData([])
        setLoading2(true)
        localStorage.setItem("link_clicked", JSON.stringify({}))
        setLoading2(false)
        setLinkClicked({})
        setGetData(!getData)
    }

    const deleteUpdateEntry = (row) => {
        setConfirmDeleteModalShow(true)
        setCurrentUpdateEntry(row)
    }
    const updateValue = (data) => {
        var dataTable = $("#update_page_table").DataTable();
        dataTable.clear();
        dataTable.draw();
    }
    const preventGoback = (event) => {
        if ((topTagModal || tagModal) && window.location.hash != "#modal") {
            if (tagModal) {
                setTagModal(false)
            } else {
                setTopTagModal(false)
            }

        }
    }
    window.addEventListener("hashchange", preventGoback, false);
    useEffect(() => {
        g_show_date_col = showDateRow;
        g_show_tag_col = showTagRow;
        let row = `<th style={{ whiteSpace: "nowrap", minWidth: "90%" }}>Title</th>`
        if (showDateRow === true) {
            row += `<th>Date</th>`
        }

        if (showTagRow === true) {
            row += `<th>Tags</th>`
        }

        if ($.fn.dataTable.isDataTable("#update_page_table")) {
            let table = $("#update_page_table").DataTable();
            table.destroy();
            $('#table_container_update').empty();
            $('#table_container_update').append(`<table class="table  table-bordered " id="update_page_table" >
            <thead>
                <tr>
                    ${row}
                </tr>
            </thead>
         </table>`);
        }
        return (() => {
            window.removeEventListener('hashchange', preventGoback);
            if (settingButtonRef.current) {
                settingButtonRef.current.removeEventListener("click", setIsShowSetting);
            }
            if (tagModalButtonRef.current) {
                tagModalButtonRef.current.removeEventListener("click", setTopTagModal);
            }
            if (resetTagButtonRef.current) {
                resetTagButtonRef.current.removeEventListener("click", resetRandomize);
            }
        })

    }, [showDateRow, showTagRow])

    const updateuse_g_reqBody=()=>{
        use_g_reqBody=true
    }
    return (
        <>
            <Modal

                show={confirmDeleteModalShow}
                onHide={() => setConfirmDeleteModalShow(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    Are you sure you want to delete?
                </Modal.Body>
                <Modal.Footer>

                    <Button onClick={deleteEntry} variant="danger" >Yes</Button> <Button onClick={() => setConfirmDeleteModalShow(false)}>No</Button>

                </Modal.Footer>
            </Modal>

            <Modal show={isShowSetting} onHide={setIsShowSetting} size="md">
                <Modal.Body>
                    <div className="row">
                        <div className="col-6">

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <input type="checkbox" className="form-check-input custom_check" checked={filterFormat === "video" ? true : false} onChange={() => { g_filterFormat = filterFormat === "video" ? "" : "video"; setFilterFormat(filterFormat === "video" ? "" : "video") }} style={{ height: "15px" }} />
                                    <span style={{ marginLeft: "30px" }}>Videos only ({videoCount})</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <input type="checkbox" className="form-check-input custom_check" checked={filterFormat === "article" ? true : false} onChange={() => { g_filterFormat = filterFormat === "article" ? "" : "article"; setFilterFormat(filterFormat === "article" ? "" : "article") }} style={{ height: "15px" }} />
                                    <span style={{ marginLeft: "30px" }}>Articles only ({articleCount})</span>
                                </div>
                            </div>
                                {user?.token  && user.is_subscribed!=="canceled" &&<> 
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <input type="checkbox" className="form-check-input custom_check" checked={showPreTitle} onChange={() => {
                                            g_show_pre_title = !showPreTitle;
                                            seShowPreTitle(!showPreTitle);
                                        }} style={{ height: "15px" }} />
                                        <span style={{ marginLeft: "30px" }}> Show pre-title </span>
                                    </div>
                                </div>
                                <br/>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <input type="checkbox" className="form-check-input custom_check" checked={readStatus === "read" ? true : false} onChange={() => {
                                            g_readStatus = readStatus === "read" ? "" : "read"
                                            setReadStatus(readStatus === "read" ? "" : "read");
                                        }} style={{ height: "15px" }} />
                                        <span style={{ marginLeft: "30px" }}> Show Read Only ({readCount})</span>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <input type="checkbox" className="form-check-input custom_check" checked={readStatus === "unread" ? true : false} onChange={() => {
                                            g_readStatus = readStatus === "unread" ? "" : "unread"
                                            setReadStatus(readStatus === "unread" ? "" : "unread");
                                        }} style={{ height: "15px" }} />
                                        <span style={{ marginLeft: "30px" }}> Show Unread Only ({(publicCount+privateCount)-readCount}) </span>
                                    </div>
                                </div>
                                
                                </>}
                                {/* <div className="form-group row">
                                    <div className="col-md-12">
                                        <input type="checkbox" className="form-check-input custom_check" checked={showRedLink} onChange={() => { g_showRedLink = !showRedLink ; setShowRedLink(!showRedLink) }} style={{ height: "15px" }} />
                                        <span style={{ marginLeft: "30px" }}>Set red links for public posts </span>
                                    </div>
                                </div> */}
                                <br/>
                        </div>
                        <div className="col-6">
                                <div className="form-group row">
                                    <div className="col-md-12" style={{paddingLeft:0}}>
                                        <input type="checkbox" className="form-check-input custom_check" checked={publicOnly === "public" ? true : false} onChange={() => {
                                            g_public_only = publicOnly === "public" ? "all" : "public";
                                            setPublicOnly(publicOnly === "public" ? "all" : "public");
                                        }} style={{ height: "15px" }} />
                                        <span style={{ marginLeft: "30px" }}>Public Only ({publicCount}) </span>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-12" style={{paddingLeft:0}}>
                                        <input type="checkbox" className="form-check-input custom_check" checked={publicOnly === "jd_user" ? true : false} onChange={() => {
                                            g_public_only = publicOnly === "jd_user" ? "all" : "jd_user";
                                            setPublicOnly(publicOnly === "jd_user" ? "all" : "jd_user");
                                        }} style={{ height: "15px" }} />
                                        <span style={{ marginLeft: "30px" }}>JD Users Only ({privateCount - publicCount}) </span>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p style={{ fontSize: `${g_is_mobile ? '10px' : '12px'} `, margin: '0px', marginLeft: '10px' }}>
                                <h1 style={{ color: 'red' ,fontSize: `${g_is_mobile ? '10px' : '12px'}`, margin: '0px',lineHeight:0.2}}>Red Links = Open For Public </h1><br />
                                <h1 style={{ color: 'blue' ,fontSize: `${g_is_mobile ? '10px' : '12px'}`, margin: '0px',lineHeight:0.2}}>Blue Links =  Pakya Personal Notes (JD Subs Only)</h1><br />
                                <h1 style={{ color: 'green' ,fontSize: `${g_is_mobile ? '10px' : '12px'}`, margin: '0px',lineHeight:0.2}}>Green Links = Real-Time Trading / Stocks Discussions (JD Subs Only) </h1><br />
                                <h1 style={{ color: 'purple' ,fontSize: `${g_is_mobile ? '10px' : '12px'}`, margin: '0px',lineHeight:0.2}}>Purple Links = JD Teaching (JD Subs Only) </h1>
                            </p>
                        </div>

                    </div>
                    <hr />
                    <div className="row float-left">
                        <Button
                            variant="primary"
                            type="submit"
                            className="btn btn-primary ml-3"
                            onClick={() => setIsShowSetting(false)}
                        >
                            Close
                        </Button>
                    </div>
                </Modal.Body>

            </Modal>

            <Helmet>
                <title>JerungDetector.Com </title>
            </Helmet>
            {loading && <ReactLoading type={"spokes"} color={"#1c56ac"} className="loader" />}
            <TagModal isShow={tagModal} closeModal={setTagModal} hiddenTag={hiddenTag} currentPost={currentPost} ma_filter_func={ma_filter_func} currentFilter={currentFilter} setCurrentFilter={ma_filter_func} filter_list={filter_list} />
            <TopTag isShow={topTagModal} closeModal={setTopTagModal} hiddenTag={hiddenTag} filter_list={filter_list} setCurrentFilter={ma_filter_func} />
            <Alert variant="danger" show={showAlert} onClose={() => setAlertShow(false)} dismissible><p>{message}</p></Alert>
            <AddNewUpdate updateuse_g_reqBody={updateuse_g_reqBody} updateValue={updateValue} isShow={addNewEntryModal} closeModal={showAddNewEntryModal} setFlag={setFlag} flag={flag} currentPost={currentPost} isEdit={isEdit} setIsEdit={setIsEdit} filter_list={filter_list} updateData={updateData} set_updateData={set_updateData} />
            <UpdateHeader isShow={showUpdateHeaderModal} closeModal={setShowUpdateHeaderModal} setFlag={setFlag} flag={flag} currentPost={headerData} />
            {windowSize?.width > 900 && <h4 style={{ textAlign: "center", marginTop: "2%" }}></h4>}




            <div className="row">
                <div className="col">
                    <div className="form-group" style={{ marginBottom: 0 }}>
                        {dateFilter !== null ?<>
                            <div className="row">
                                <div className="col-auto" style={{ width: windowSize?.width < 900 ? "100%" : "400px",marginTop:'10px' }} >
                                    Updates {moment(dateFilter).format('DD/MM/YYYY')}<Link className="btn" to={'/'} style={{display:"inline"}}> <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 384 512" color="#1c56ac"><path fill="#fa0000" d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg></Link>
                                </div>
                            </div> <br/></>:
                        <div className="row">
                            <div className="col-auto" style={{ width: "100%", marginTop: "7px" }}>
                                Filter Tags
                            </div>
                            {/* style={{ width: windowSize?.width < 900 ? "100%" : "400px" }} */}
                            <div className="col-auto" style={{ width: windowSize?.width < 900 ? "100%" : "400px" }} >
                                <div className="form-group" style={{ width: "100%",marginBottom:'2px' }}>
                                    <div>
                                        <Select
                                            name="filter_book"
                                            components={{
                                                Option: OptionComponent,
                                                GroupHeading: CustomGroupHeading,
                                                DropdownIndicator: DropdownIndicator,
                                            }}
                                            options={is_show_fav? [{label:'⭐ Starred Posts',value:'star'},...filter_list]:filter_list}
                                            isClearable={true}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select filter.."
                                            onChange={ma_filter_func}
                                            isDisabled={filter_status}
                                            value={currentFilter}
                                            isSearchable={isSearchable}
                                            onMenuClose={handleMenuClose}
                                            styles={customStyles}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {isEditMode && <>
                            <button className="btn btn-success"  onClick={() => showAddNewEntryModal(true)} style={{ marginRight: "4px" }} >
                                Add new
                            </button>
                            <button className="btn btn-secondary"  onClick={() => showUpdateHeader(true)} style={{ marginRight: "4px" }} >
                                Update Button
                            </button>
                        </>
                        }
                    </div>

                </div>

            </div>






            <section className="gallery-block grid-gallery" >
                <div className="container" style={{ padding: `${windowSize?.width < 900 && headlineOnly === false ? "" : 0}` }}>
                    {headlineOnly === true ? <>

                        <div id="table_container_update" className="table-responsive">
                            <table className="table table-bordered " id="update_page_table" >
                                <thead>
                                    <tr>
                                        <th style={{ whiteSpace: "nowrap", minWidth: "90%" }}>Title</th>
                                        <th>Tags</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>

                    </> : <div className="row">

                        {updateData.length > 0 && updateData.map((obj, i) => {
                            return <>
                                {windowSize?.width < 900 ? <>
                                    <div className="col-12" style={{ padding: 0 }} key={i} onMouseOver={() => setIndex(i)} onMouseLeave={() => setIndex(9999)} >
                                        <div className="scale-on-hover update_div_mobile" style={{ backgroundColor: `${obj.is_public ? '#dff0f7' : '#b1cfdc'}` }}>


                                            <div className="button-container">
                                                {isEditMode && (
                                                    <>
                                                        <button className="btn btn-sm btn-primary" onClick={() => editEntry(obj)}>
                                                            <FontAwesomeIcon icon={faPen} />
                                                        </button>
                                                        <button className="btn btn-sm btn-danger" onClick={() => deleteUpdateEntry(obj)}>
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </button>
                                                    </>
                                                )}
                                            </div>

                                            <a onClick={() => goTo(obj)} style={{ textDecoration: "none", cursor: "pointer", color: `${link_clicked[obj._id] ? 'purple' : 'black'}` }}>

                                                <div className="row">
                                                    <div className="col-auto" style={{ paddingRight: "1px" }}>
                                                        {obj.image === null ? <img className="img-fluid image" height={100} width={110} src="https://jerungdetector.com/media/editor/1693684930730-AVGO_2023-09-03_04-02-01.png" /> : <img height={100} width={110} src={obj.image} />}

                                                    </div>
                                                    <div className="col-7" style={{ paddingRight: "1px", marginLeft: "3px" }}>
                                                        <h6 style={{ fontSize: "12px" }}><strong>{capitalizeWords(obj.title)}</strong></h6>

                                                        <p style={{ wordWrap: "break-word", marginTop: "5px", fontSize: "11px" }} >
                                                            {obj.sub_title}     <br />
                                                        </p>


                                                    </div>
                                                </div>
                                            </a>
                                            <small style={{ position: "absolute", bottom: "10px", marginLeft: "130px", fontSize: "9px", marginBottom: "2px" }}>  {moment(obj?.date).format("DD/MM/YYYY - HH:mm A")} {obj?.format?.length > 0 && <Badge variant="info">({obj.format[0].label})</Badge>}</small>

                                            <button className="btn btn-sm btn-light" onClick={() => showTagModal(obj)} style={{ position: "absolute", bottom: "15px", right: "5px" }}><FontAwesomeIcon icon={faQuestionCircle} /> </button>

                                        </div>
                                    </div>

                                </> : <>
                                    <div className="col-md-6 col-lg-3 item col-sm-12 col-12" key={i}>
                                        <div className="scale-on-hover update_div" onMouseOver={() => setIndex(i)} onMouseLeave={() => setIndex(9999)} style={{ backgroundColor: `${obj.is_public ? '#dff0f7' : '#b1cfdc'}` }}>
                                            <a onClick={() => goTo(obj)} style={{ textDecoration: "none", cursor: "pointer", color: `${link_clicked[obj._id] ? 'purple' : 'black'}` }}>
                                                {obj.image === null ? <img className="img-fluid image" height={200} width={350} src="https://jerungdetector.com/media/editor/1693684930730-AVGO_2023-09-03_04-02-01.png" /> : <img className="img-fluid image" src={obj.image} />}

                                                <div style={{ marginTop: "10px" }}>

                                                    <h6 className="text-center"><strong>{capitalizeWords(obj.title)}</strong></h6>

                                                    <p style={{ wordWrap: "break-word", marginTop: "5px", fontSize: "12.8px" }} >
                                                        {obj.sub_title}     <br />

                                                    </p>
                                                    <small style={{ position: "absolute", bottom: "3px", fontSize: "12px" }}>{moment(obj?.date).format("DD/MM/YYYY - HH:mm A")} {obj?.format?.length > 0 && <Badge variant="info" style={{ fontSize: "12px", marginLeft: "2px" }}>{obj.format[0].label}</Badge>}</small>
                                                </div>
                                            </a>

                                            {currentIndex === i && isEditMode && <>
                                                <button className="btn btn-sm btn-primary" onClick={() => editEntry(obj)} style={{
                                                    position: "absolute",
                                                    top: "5px",
                                                    right: "5px"
                                                }}><FontAwesomeIcon icon={faPen} /></button> <br />
                                                <button className="btn btn-sm btn-danger" onClick={() => deleteUpdateEntry(obj)} style={{
                                                    position: "absolute",
                                                    top: "40px",
                                                    right: "5px"
                                                }}><FontAwesomeIcon icon={faTrashAlt} /></button>

                                            </>}

                                            <button className="btn btn-sm btn-light" onClick={() => showTagModal(obj)} style={{ position: "absolute", bottom: "5px", right: currentIndex === i ? "5px" : "20px" }}
                                                data-toggle="tooltip"
                                                title="Tags"
                                            ><FontAwesomeIcon icon={faQuestionCircle} /> </button>

                                        </div>
                                    </div>
                                </>}


                            </>


                        })}
                    </div>}
                    <div ref={loader} style={{ height: "10px", }}>
                        {loading && <div className="text-center" style={{ fontSize: "15px", color: "#007bff" }}><strong>Loading...</strong></div>}
                    </div>
                    {hasMore === false && <p className="text-center" style={{ fontSize: "15px", color: "#007bff" }}><strong>No more data.</strong></p>}

                </div>
            </section>
            {headerData?.buttonVisibility === "all" && <div className="full-width-div">
                <button className="btn" style={{ backgroundColor: `${headerData?.buttonColor}`, marginTop: "10px", marginBottom: "10px", color: "#fff" }} onClick={moveToPage}>{headerData?.buttonText}</button>
            </div>}
            {user?.token ? <></> : headerData?.buttonVisibility === "non_sub_user" && <div className="full-width-div">
                <button className="btn" style={{ backgroundColor: `${headerData?.buttonColor}`, marginTop: "10px", marginBottom: "10px", color: "#fff" }} onClick={moveToPage}>{headerData?.buttonText}</button>
            </div>}
        </>
    );
};

export default UpdatesPage;