

const Config = {
    defaultPath: '/dashboard',
    basename: '',
    layout: 'vertical',
    subLayout: '',
    collapseMenu: false,
    layoutType: 'menu-light',
    headerBackColor: 'background-grd-dark',
    rtlLayout: false,
    navFixedLayout: true,
    headerFixedLayout: false,
    boxLayout: false,
    BASE_URL:"https://jerungdetector.com"
};
export default Config;

///BASE_URL:"http://jerungdetector.com"


// http://127.0.0.1:4000