import { useState,useEffect } from "react";
import axios from "axios";
import { Button, Spinner ,Alert ,Row ,Col} from "react-bootstrap";
import Config from "../../config";
import {addSessionData} from '../../store/action/sessionAction';
import {addBookList ,getUserFilter} from '../../store/action/filterAction';
import {useDispatch} from 'react-redux';
import { useHistory,useLocation,Link } from "react-router-dom";


const generateRandomString = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }  
    return result;
};

const LogIN = () => {
    const dispatch=useDispatch();
    const location = useLocation();
    const histroy=useHistory()

    const [buttonStatus,setButtonStatus]=useState(false)  
    const [showAlert, setAlertShow] = useState(false);
    const [message,setMessage]=useState()

    const [usernName, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [came_from,setCameFrom]=useState() 
    useEffect(()=>{
        if(location.state !=null){
    
            setCameFrom("https://jerungdetector.com"+location.state.from)
        }else{
          setCameFrom("https://jerungdetector.com/")
        }
    
      },[])

    const loginUser = (e) => {
        e.preventDefault();
        setButtonStatus(true);

        let device_id = localStorage.getItem('device_id');
        if (!device_id) { 
            const randomString = generateRandomString(19);
            localStorage.setItem("device_id", randomString)
            device_id=randomString
        }


        axios.post(Config.BASE_URL +"/api/user/login",{
            user_name:usernName,
            password:password,
            device_id
        }).then((res)=>{
         
            if(res.data.success===true){
                localStorage.setItem('jwtToken',JSON.stringify(res.data.userInfo));
                dispatch(addSessionData(res.data.userInfo))
                dispatch(addBookList())
                dispatch(getUserFilter())
                if(res.data.userInfo.is_subscribed===false){
                    histroy.push("/subscription")
                }else{
                    window.location=came_from
                    histroy.push(came_from)
                }
          
            }else{
                setAlertShow(true)
                setMessage(res.data.message)
                setButtonStatus(false);
            }
            
        }).catch((e)=>{
            setAlertShow(true)
            setMessage(e.message)
        })
    };
    return (
        <>
            <Row>
                <Col>
                    <Alert variant="danger" show={showAlert} onClose={() => setAlertShow(false)} dismissible><p>{message}</p></Alert>
                </Col>
             
            </Row>
            <div className="row">
            
                <div className="col-md-4"></div>
                <div className="col-md-4" style={{ marginTop: "15%" }}>
                    <form onSubmit={loginUser}>
                        <div className="form-group">
                            <label htmlFor="user_name">Username/Email</label>
                            <input
                                type="text"
                                className="form-control"
                                id="user_name"
                                name="user_name"
                                value={usernName}
                                onChange={(e)=>setUsername(e.target.value)}
                                placeholder="Enter username/email"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Password"
                                name="password"
                                value={password}
                                onChange={(e)=>setPassword(e.target.value)}
                            />
                        </div>
                        <div style={{ textAlign: "center" }}>
                            {buttonStatus ? (
                                <Button
                                    variant="primary"
                                    disabled
                                    className="btn btn-block btn-primary mb-4"
                                >
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Loging...
                                </Button>
                            ) : (
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="btn btn-block btn-primary mb-4"
                                >
                                    Signin
                                </Button>
                            )}
                        </div>
                    </form>
                    <div className="row">
                        <div className="col"> <a ><Link to="/forgot_password">Forgot password?</Link></a></div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="row">
                        <div className="col"><a href="https://jerungdetector.com/join">How to Join?</a></div>
                    </div>
                </div>
                <div className="col-md-4"></div>
            </div>
        </>
    );
};

export default LogIN;
